import React from "react";
import PagePadding from "../components/layout/PagePadding";
import Metadata from "../components/layout/Metadata";
import LandingNav from "../components/nav/LandingNav";
import LandingHeader from "../components/header/LandingHeader";

function Landing() {
  return (
    <PagePadding>
      <Metadata
        url={"https://www.viewmaxer.com"}
        image={"https://www.viewmaxer.com/preview-cover.png"}
        title={"ViewMaxer"}
        description={"Turn your videos into hits with ViewMaxer"}
      />
      <div>
        <LandingNav />
        <LandingHeader />
        <img
          src="/pngs/LandingPreview.png"
          alt="ViewMaxer"
          className="w-[70vw] md:w-[50vw] absolute bottom-0 right-0"
        />
      </div>
    </PagePadding>
  );
}

export default Landing;
