import React from "react";

function LandingHeader() {
  return (
    <div className="flex flex-col mt-[25px] lg:mt-[50px] w-[90vw] lg:w-[40vw] mx-auto lg:mx-0 gap-[25px] text-center lg:text-left">
      <h3 className="mb-[-20px] font-black leading-[130%] text-[40px] lg:text-[55px]">
        Turn your videos into hits
      </h3>
      <p className="text-[20px] font-medium text-[#5B5B5B]">
        Just upload your video and let AI determine how to make it more viral.
      </p>
      <button className="w-[100%] lg:w-[300px] h-[55px] bg-[#7273F1] rounded-full text-white font-semibold">
        Get Started
      </button>
      <section className="flex flex-row gap-[10px] items-center justify-center lg:justify-start">
        <div className="flex flex-row">
          <img
            src="/pngs/AvatarThree.png"
            alt="AvatarThree"
            className="w-[30px] h-[30px] object-cover rounded-full border-[1px] border-[#5B5B5B]"
          />
          <img
            src="/pngs/AvatarTwo.png"
            alt="AvatarTwo"
            className="w-[30px] h-[30px] object-cover rounded-full border-[1px] border-[#5B5B5B] ml-[-10px]"
          />
          <img
            src="/pngs/AvatarOne.png"
            alt="AvatarOne"
            className="w-[30px] h-[30px] object-cover rounded-full border-[1px] border-[#5B5B5B] ml-[-10px]"
          />
        </div>
        <p className="text-[15px] text-[#5B5B5B] font-medium">
          +10,000 Creators and Counting!
        </p>
      </section>
    </div>
  );
}

export default LandingHeader;
