import React from "react";

function LandingNav() {
  return (
    <nav className="flex flex-row gap-[15px] items-center h-[100px]">
      <img
        src={"/svgs/Logo.svg"}
        height={40}
        width={40}
        className="rounded-full"
        alt="Logo"
      />
      <h1 className="font-bold text-[20px]">ViewMaxer</h1>
    </nav>
  );
}

export default LandingNav;
